import React, {useState} from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import {MailSended} from "./overlay/BannerNotifications";


function Contact() {
    const [emailSent, setEmailSent] = useState(false);

    const recaptchaRef = React.createRef();
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const recaptchaValue = recaptchaRef.current.getValue();

        const formData = {
            firstName: e.target.elements.firstName.value,
            lastName: e.target.elements.lastName.value,
            email: e.target.elements.email.value,
            phoneNumber: e.target.elements.phoneNumber.value,
            message: e.target.elements.message.value,
            recaptcha: recaptchaValue,
        };

        try {
            const response = await fetch(`${window.location.protocol}//${window.location.hostname}:3001/send-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                console.log('Email sent successfully');
                setEmailSent(true);
            } else {
                console.error('Failed to send email');
            }
        } catch (error) {
            console.error('Error sending email', error);
        }
    };

    return (
        <main className="py-14">
            <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
                {emailSent ? (
                    <MailSended />

                ) : (
                    <div>
                <div className="max-w-lg mx-auto space-y-3 sm:text-center">
                    <h3 className="text-indigo-600 font-semibold">
                        Contact
                    </h3>
                    <p className="text-gray-800 text-3xl sm:text-5xl dm-sans-700">
                        Et si on prennait contact ?
                    </p>
                    <p>
                        Nous répondrons à vos questions dans les plus brefs délais
                    </p>
                </div>
                <div className="mt-12 max-w-lg mx-auto">
                    <form onSubmit={handleFormSubmit} className="space-y-5">
                        <div className="flex flex-col items-center gap-y-5 gap-x-6 [&>*]:w-full sm:flex-row">
                            <div>
                                <label className="font-medium">
                                    Prénom
                                </label>
                                <input
                                    type="text"
                                    required
                                    name="firstName"
                                    className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                                />
                            </div>
                            <div>
                                <label className="font-medium">
                                    Nom de famille
                                </label>
                                <input
                                    type="text"
                                    required
                                    name="lastName"
                                    className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                                />
                            </div>
                        </div>
                        <div>
                            <label className="font-medium">
                                Email
                            </label>
                            <input
                                type="email"
                                required
                                name="email"
                                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                            />
                        </div>
                        <div>
                            <label className="font-medium">
                                Numéro de téléphone
                            </label>
                            <div className="relative mt-2">
                                <input
                                    type="tel"
                                    placeholder="+33 6 12 34 56 78"
                                    required
                                    name="phoneNumber"
                                    className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                                />
                            </div>
                        </div>
                        <div>
                            <label className="font-medium">
                                Votre message
                            </label>
                            <textarea
                                required
                                name="message"
                                className="w-full mt-2 h-36 px-3 py-2 resize-none appearance-none bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                            ></textarea>
                        </div>
                        <div className="flex justify-center mt-2">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LeE668pAAAAAEObX13c7lBToc-4NJMYqpcshy9i"
                            />
                        </div>
                        <button
                            type="submit"
                            className="w-full px-4 py-2 text-white font-medium bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-600 rounded-lg duration-150"
                        >
                            Envoyer
                        </button>
                    </form>
                </div>
                </div>
        )}
            </div>
        </main>
    )
}

export default Contact;
