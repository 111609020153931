import { WifiIcon} from "@heroicons/react/16/solid";
import {TvIcon} from "@heroicons/react/24/outline";
import {Car, ChefHat} from "@phosphor-icons/react";

const stats = [
    {
        data: "4",
        title: "Voyageurs"
    },
    {
        data: "2",
        title: "Chambres"
    },
    {
        data: "700m",
        title: "De la mer"
    },
    {
        data: "100m2",
        title: "Surface habitable"
    },
    {
        data: "500m²",
        title: "Jardin clos"
    },
    {
        data: "Plein sud",
        title: "Exposition au soleil"
    },
]

const equipments = [
    {
        name: "WiFi Gratuit",
        icon: <WifiIcon className="w-6 h-6 Color-Blue-Klein"/>,
    },
    {
        name: "Tv connecté",
        icon: <TvIcon className="w-6 h-6 Color-Blue-Klein"/>,
    },
    {
        name: "Cuisine équipée",
        icon: <ChefHat className="w-6 h-6 Color-Blue-Klein"/>,
    },
    {
        name: "Parking gratuit",
        icon: <Car className="w-6 h-6 Color-Blue-Klein" />
    },

]
function Numbers() {

    return (
        <section className="py-14">
            <div className="max-w-screen-xl mx-auto px-4 md:px-8">
                <div className="max-w-2xl mx-auto text-center">
                    <h3 className="text-2xl dm-sans-700 sm:text-4xl">
                        Jolie maison de charme à Préfailles
                    </h3>
                    <p className="mt-3">
                        Idéal pour un moment de détente, de repos et de découverte de la côte de
                        Jade. Venez profiter d une pièce de vie avec cuisine équipée, une mezzanine cocooning avec coin
                        télé, un salon véranda, 2 belles chambres, le tout donnant sur une terrasse plein sud avec un
                        jardin clos sans vis à vis.
                    </p>
                </div>
                <div className="mt-12">
                    <ul className="flex flex-col items-center justify-center gap-y-10 sm:flex-row sm:flex-wrap lg:divide-x">
                        {
                            stats.map((item, idx) => (
                                <li key={idx} className="text-center px-12 md:px-16">
                                    <h4 className="text-4xl text-indigo-600 dm-sans-700">{item.data}</h4>
                                    <p className="mt-3 dm-sans-500">{item.title}</p>
                                </li>
                            ))
                        }
                    </ul>
                </div>
                <div className="max-w-2xl mx-auto text-center mt-4">
                    <p>
                        Maison individuelle rénovée récemment avec jardin clos & une terrasse plein
                        sud.<br/>
                        Parking clos à votre disposition.
                        Grande pièce de vie avec cuisine toute équipée.<br/>
                        Salon TV en mezzanine Véranda avec salon donnant sur terrasse et jardin.<br/>
                        2 Chambres dont une donnant sur la terrasse extérieur<br/>
                    </p>
                </div>
                <div className="mt-3">
                    <ul className="flex flex-col items-center justify-center gap-y-10 sm:flex-row sm:flex-wrap lg:divide-x">
                        <div className="mt-12">
                            <ul className="flex flex-col items-center justify-center gap-y-10 sm:flex-row sm:flex-wrap lg:divide-x">
                                {equipments.map((item, idx) => (

                                    <li key={idx} className="text-center px-12 md:px-16 flex flex-col items-center">
                                        {item.icon}
                                        <p className="mt-3 dm-sans-500">{item.name}</p>
                                    </li>

                                ))}
                            </ul>
                        </div>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Numbers;