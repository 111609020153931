import React, {useState} from 'react';
import {SocialIcon} from "react-social-icons";
import {MailSended} from "../overlay/BannerNotifications";
import ReCAPTCHA from "react-google-recaptcha";

function InformNewExperiment(props){

    return (
        <div>
        <div tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50">
            <div className="relative p-4 w-full max-w-md max-h-full z-60">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            Comment vous contacter ?
                        </h3>
                        <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="crypto-modal" onClick={props.handleModalClose}>
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="flex-grow overflow-y-auto">
                    <div className="p-4 md:p-5">
                        <p className="text-gray-600 dark:text-gray-300">
                            Soyez le.a premier.ère à être informé.e de l'ouverture de notre nouvelle expérience en 2024. Suivez nous sur Instagram pour ne rien manquer.
                        </p>
                        <hr className="my-4 border-gray-200 dark:border-gray-700"/>
                        <ul className="my-4 space-y-3">
                            <li>
                                <div>
                                    <a href="https://www.instagram.com/atelierdemarie44?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                                       target="_blank" rel="noopener noreferrer"
                                       className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                                        <span
                                            className="flex-1 ms-3 whitespace-nowrap">Instagram - Atelier de Marie</span>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <a href="https://www.instagram.com/gitedemarie44?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                                       target="_blank" rel="noopener noreferrer"
                                       className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                                        <span
                                            className="flex-1 ms-3 whitespace-nowrap">Instagram - Gîte de Marie</span>
                                        </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default InformNewExperiment;