function Histoire() {

    return (
        <section className="py-14">
            <div className="max-w-screen-xl mx-auto px-4 md:px-8">
                <div className="max-w-2xl mx-auto text-center">
                    <h3 className="text-2xl dm-sans-700 sm:text-4xl">
                        Bienvenue dans l'Univers Créatif de Marie: Une Histoire Familiale au Bord de la Mer
                    </h3>
                    <p className="mt-3">
                        Plongez-vous dans l'histoire d'une passion qui traverse les générations, tissée dans les fibres
                        de notre famille et portée par les vagues de l'océan. Depuis aussi longtemps que je me
                        souvienne, les aiguilles à tricoter cliquetaient et les machines à coudre ronronnaient dans
                        notre foyer familial. C'était un héritage précieux, transmis avec amour par les femmes qui m'ont
                        précédée, un héritage imprégné de créativité et de tradition.

                        <br/><br/>
                        Mon voyage dans le monde de la couture et de la création a commencé modestement, dans les
                        recoins tranquilles de notre maison au bord de la mer. Mais ce n'était pas seulement un lieu de
                        création; c'était aussi un lieu de retrouvailles, où les rires des enfants et le murmure des
                        vagues se mêlaient harmonieusement. Cette maison, érigée avec amour par mon grand-père il y a
                        des décennies, avait vu grandir nos souvenirs les plus chers. Elle était le témoin silencieux de
                        nos étés insouciants et de nos hivers chaleureux en famille.
                        <br/><br/>
                        Après des années de rêveries et de planifications, j'ai finalement décidé de donner vie à mes
                        aspirations. J'ai entrepris de rénover notre maison de vacances au bord de la Côte de Jade, non
                        seulement pour honorer la promesse faite à mon grand-père, mais aussi pour offrir un refuge
                        chaleureux à ceux qui cherchent le calme et l'inspiration au bord de l'océan.
                        <br/><br/>
                        C'est ainsi que notre humble maison familiale est devenue un gîte accueillant, imprégné de
                        l'essence de la mer et de la créativité. C'était une fusion naturelle de mes deux passions, un
                        mariage entre l'éclat bleu de l'océan et la palette infinie des fils et des tissus.
                        <br/><br/>
                        Depuis que j'ai entrepris ce nouveau chapitre de ma vie, les derniers mois ont été une aventure
                        passionnante. Accueillir des voyageurs et les guider dans notre coin de paradis côtier, partager
                        nos histoires et nos traditions, a été une source infinie de joie et d'inspiration. Et
                        maintenant, je suis ravie d'annoncer une nouvelle dimension à notre gîte: des retraites
                        créatives au printemps.
                        <br/><br/>
                        Imaginez-vous, entourés par les reflets argentés de l'océan, plongés dans un monde d'artisanat
                        et de création. Avec chaque retraite, nous offrirons un espace où les trésors oubliés peuvent
                        retrouver vie, où les matériaux recyclés se métamorphosent en œuvres d'art uniques, imprégnées
                        de l'âme de la mer.
                        <br/><br/>
                        Je vous invite à réserver dès maintenant votre place pour une expérience inoubliable au
                        printemps 2024. Venez rejoindre notre communauté de créateurs, laissez-vous inspirer par les
                        vagues qui murmurent des secrets de création et par les brises marines qui apportent un souffle
                        de fraîcheur à votre imagination.
                        <br/><br/>
                        Au plaisir de vous accueillir dans notre coin de paradis,
                    </p>

                    <h4 className="mt-8 golden-hopes text-7xl">
                        Marie
                    </h4>
                </div>

            </div>
        </section>
    )
}

export default Histoire;