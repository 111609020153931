import React from 'react';
import logo_booking from "../../img/logo/logo_booking.svg";
import logo_airbnb from "../../img/logo/logo_airbnb.png";

function ModalRentalSelect(props){
    return (
        <div tabIndex="-1" aria-hidden="true"
             className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50"
        >
            <div className="relative p-4 w-full max-w-md max-h-full z-60">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div
                        className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            Réservez vos vacances
                        </h3>
                        <button type="button"
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                data-modal-toggle="crypto-modal" onClick={props.handleModalClose}>
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                 fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="p-4 md:p-5">
                        <p className="text-sm font-normal text-gray-500 dark:text-gray-400">Choisissez votre
                            plateforme de réservation :</p>
                        <ul className="my-4 space-y-3">
                            <li>
                                <a href="https://www.booking.com/hotel/fr/le-gite-de-marie-prefailles.fr.html"
                                   target="_blank" rel="noopener noreferrer"
                                   className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                                    <img src={logo_booking} alt="Booking" className="w-6 h-6"/>
                                    <span className="flex-1 ms-3 whitespace-nowrap">Booking</span>
                                    <span
                                        className="inline-flex items-center justify-center px-2 py-0.5 ms-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400">Le plus populaire</span>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.airbnb.fr/rooms/53333677?guests=1&adults=1&s=67&unique_share_id=985fbddd-cd2e-49af-8649-ef6987858d83"
                                   target="_blank" rel="noopener noreferrer"
                                   className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                                    <img src={logo_airbnb} alt="Airbnb" className="w-6 h-6"/>
                                    <span className="flex-1 ms-3 whitespace-nowrap">Airbnb</span>
                                </a>
                            </li>
                        </ul>
                        <div>
                            <a href="/contact" onClick={props.handleModalClose}
                               className="inline-flex items-center text-xs font-normal text-gray-500 hover:underline dark:text-gray-400">
                                <svg className="w-3 h-3 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                     fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="2"
                                          d="M7.529 7.988a2.502 2.502 0 0 1 5 .191A2.441 2.441 0 0 1 10 10.582V12m-.01 3.008H10M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                </svg>
                                Vous souhaitez nous contacter en direct ?</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalRentalSelect;