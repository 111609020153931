import {
    SquaresPlusIcon,
    UserGroupIcon,
    BookOpenIcon,
    HomeIcon,
    CalendarIcon,
} from "@heroicons/react/24/solid";

export function Core404() {
    const navigations = [
        {
            icon: <HomeIcon className="w-6 h-6 text-indigo-600" />,
            title: "Accueil",
            desc: "Retournez à la page d'accueil, et découvrez le Gîte de Marie",
            href: "/"
        },
        {
            icon: <BookOpenIcon className="w-6 h-6 text-indigo-600" />,
            title: "Histoire du Gîte",
            desc: "Retrouvez l'histoire du Gîte de Marie",
            href: "/histoire"
        },
        {
            icon: <CalendarIcon className="w-6 h-6 text-indigo-600" />,
            title: "Reservation",
            desc: "Réservez votre séjour au Gîte de Marie sur les plateformes de réservation",
            href: "/"
        }
    ]

    return (
        <main>
            <div className="max-w-screen-xl mx-auto flex items-center justify-start mt-12 md:px-8">
                <div className="max-w-lg mx-auto text-gray-600">
                    <div className="space-y-3 text-center">
                        <p className="text-gray-800 text-4xl font-semibold sm:text-5xl">
                            Page introuvable
                        </p>
                        <p>
                            La page que vous recherchez n'existe pas ou a été déplacée.
                        </p>
                    </div>
                    <div className="">
                        <ul className="divide-y">
                            {
                                navigations.map((item, idx) => (
                                    <li key={idx} className="flex gap-x-4 py-6">
                                        <div className="flex-none w-14 h-14 text-indigo-600 flex items-center justify-center">
                                            {item.icon}
                                        </div>
                                        <div className="space-y-1">
                                            <h4 className="text-gray-800 font-medium">{item.title}</h4>
                                            <p>
                                                {item.desc}
                                            </p>
                                            <a href={item.href} className="text-sm text-indigo-600 duration-150 hover:text-indigo-400 font-medium inline-flex items-center gap-x-1">
                                                En savoir plus
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                                    <path fillRule="evenodd" d="M5 10a.75.75 0 01.75-.75h6.638L10.23 7.29a.75.75 0 111.04-1.08l3.5 3.25a.75.75 0 010 1.08l-3.5 3.25a.75.75 0 11-1.04-1.08l2.158-1.96H5.75A.75.75 0 015 10z" clipRule="evenodd" />
                                                </svg>
                                            </a>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </main>
    )
}