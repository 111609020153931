import './App.css';
import { SpeedInsights } from "@vercel/speed-insights/react"
import {NavbarWithMegaMenu} from './componants/global/Navbar';
import Contact from './componants/Contact';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GoToVendor from "./componants/global/GoToVendor";
import Footer from "./componants/global/Footer";
import Review from "./componants/home/Review";
import HomePage from "./HomePage";
import {Gallery} from "./componants/home/Carousel";
import Histoire from "./componants/histoire/Histoire";
import {Core404} from "./componants/global/404";
import {GuestZoneNotAvailable} from "./componants/guest/GuestZone";
import { Analytics } from "@vercel/analytics/react"
function App() {
    return (
        <div>
            <Analytics/>
            <Router>
                <SpeedInsights />
                <GoToVendor/>
                <div className="mt-4 mb-4">
                    <NavbarWithMegaMenu/>
                </div>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="*" element={<Core404 />}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/galerie" element={<Gallery/>}/>
                    <Route path="/histoire" element={<Histoire/>}/>
                    <Route path="/espace-invites" element={<GuestZoneNotAvailable/>}/>
                </Routes>

                <Review/>
                <Footer/>
            </Router>
        </div>
    );
}

export default App;
