import React from "react";

import home from "../../img/home/home.webp";
import {ArrowUpRightIcon} from "@heroicons/react/24/solid";
import {Button} from "@material-tailwind/react";
import ModalRentalSelect from "../global/ModalRentalSelect";

function Home() {
    const [openModal, setOpenModal] = React.useState(false);


    const handleModalOpen = () => {
        setOpenModal(true);
    };

    const handleModalClose = () => {
        setOpenModal(false);
    }
    return (
        <>
        <section className="py-14">
            <div className="max-w-screen-xl mx-auto md:px-8">
                <div className="items-center gap-x-12 sm:px-4 md:px-0 lg:flex">
                    <div className="flex-1">
                        <img src={home} className="md:max-w-lg sm:rounded-lg" alt=""/>
                    </div>
                    <div className="max-w-xl px-4 space-y-3 mt-6 sm:px-0 md:mt-0 lg:max-w-2xl">
                        <p className="text-3xl sm:text-5xl dm-sans-700">
                            Réservez vos prochaines vacances au bord de mer
                        </p>
                        <p className="mt-3 text-gray-600">
                            Le Gîte de Marie est situé à 5 min à pied de la plage, calme et reposant, c’est l’endroit
                            idéal pour vos futures vacances
                        </p>
                        <Button variant="gradient" size="sm" onClick={handleModalOpen}>
                        <span className="flex items-center josefin-sans-bold">
                            <ArrowUpRightIcon className="ml-0 mr-3 h-4 w-4"/>
                            Réservez vos vacances
                        </span>
                        </Button>
                    </div>
                </div>
            </div>
        </section>
            {/* Condition pour afficher le modal */}
            {openModal && (
                <div>
                    <div className="overlay"></div>
                    <ModalRentalSelect handleModalClose={handleModalClose} />
                </div>
            )}
        </>
    );
}

export default Home;