import React, { useState } from 'react';
import PhotoAlbum from "react-photo-album";
import AliceCarousel from 'react-alice-carousel';
import Modal from 'react-modal';

const photos = [
    { src: require("../../img/maison/webp/Chambre_1_1.webp"), width: 800, height: 600 },
    { src: require("../../img/maison/webp/Chambre_1_2.webp"), width: 800, height: 600 },
    { src: require("../../img/maison/webp/Chambre_1_3.webp"), width: 800, height: 600 },
    { src: require("../../img/maison/webp/Chambre_2_1.webp"), width: 800, height: 600 },
    { src: require("../../img/maison/webp/Coin_Ext.webp"), width: 800, height: 600 },
    { src: require("../../img/maison/webp/Cuisine.webp"), width: 800, height: 600 },
    { src: require("../../img/maison/webp/Cuisine_2.webp"), width: 800, height: 600 },
    { src: require("../../img/maison/webp/Jardin.webp"), width: 800, height: 600 },
    { src: require("../../img/maison/webp/Jardin_2.webp"), width: 800, height: 600 },
    { src: require("../../img/maison/webp/Jardion_3.webp"), width: 800, height: 600 },
    { src: require("../../img/maison/webp/Livret_D_Accueil.webp"), width: 800, height: 600 },
    { src: require("../../img/maison/webp/Mezzanine_TV.webp"), width: 800, height: 600 },
    { src: require("../../img/maison/webp/Salon_Ete.webp"), width: 800, height: 600 },
    { src: require("../../img/maison/webp/Salon_TV_Etage.webp"), width: 800, height: 600 },
    { src: require("../../img/maison/webp/Salon_TV_Etage_2.webp"), width: 800, height: 600 },
    { src: require("../../img/maison/webp/Sejour.webp"), width: 800, height: 600 },
    { src: require("../../img/maison/webp/Terrasse.webp"), width: 800, height: 600 },
    { src: require("../../img/maison/webp/Terrasse_2.webp"), width: 800, height: 600 },
    { src: require("../../img/maison/webp/Veranda.webp"), width: 800, height: 600 },
    { src: require("../../img/maison/webp/Veranda_2.webp"), width: 800, height: 600 }
];

const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
};

const Carousel = () => {
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [selectedPhoto, setSelectedPhoto] = useState(null);

        const handlePhotoClick = (index) => {
            setSelectedPhoto(photos[index]);
            setIsModalOpen(true);
        };

        const handleCloseModal = () => {
            setIsModalOpen(false);
            setSelectedPhoto(null);
        };

        return (
            <div className="max-w-screen-xl mx-auto md:px-8">
                <AliceCarousel
                    autoPlay
                    autoPlayInterval={3000}
                    responsive={responsive}
                    infinite
                    mouseTracking
                    className="max-h-screen"
                >
                    {photos.map((photo, index) => (
                        <img
                            key={index}
                            src={photo.src}
                            alt={`Image ${index}`}
                            width={photo.width}
                            height={photo.height}
                            onClick={() => handlePhotoClick(index)}
                        />
                    ))}
                </AliceCarousel>

                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={handleCloseModal}
                    contentLabel="Photo Modal"
                    className="fixed top-0 left-0 right-0 bottom-0 flex w-full h-full justify-center items-center z-40"
                    overlayClassName="fixed top-0 left-0 w-full h-full bg-black bg-opacity-60"
                    onClick={handleCloseModal}
                >
                    <div className=" p-4 mt-40 w-full overflow-y-auto z-70">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <div className="p-4 md:p-5">
                                <AliceCarousel
                                    infinite
                                    mouseTracking
                                    activeIndex={photos.indexOf(selectedPhoto)}
                                    disableButtonsControls
                                    disableDotsControls
                                    onClick={handleCloseModal}
                                    className="align-center"
                                >
                                    {photos.map((photo, index) => (
                                        <img
                                            key={index}
                                            src={photo.src}
                                            alt={`Image ${index}`}
                                            width={photo.width}
                                            height={photo.height}
                                            onClick={handleCloseModal}
                                        />
                                    ))}
                                </AliceCarousel>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
};

export function Carousel_home() {
    return (
        <div className="bg-white">
            <Carousel/>
        </div>
    );
}

export function Gallery() {
    return (
        <div className="max-w-screen-xl mx-auto md:px-8">
            <PhotoAlbum layout="rows" photos={photos}/>
        </div>
    );
}
