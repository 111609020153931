import React from 'react';
import {Button, Typography} from "@material-tailwind/react";
import {
    ArrowUpIcon
} from "@heroicons/react/24/solid";

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};


function Footer() {
    return (
        <footer className="text-gray-500 bg-white px-4 py-5 max-w-screen-xl mx-auto md:px-8">
            <div className="max-w-lg sm:mx-auto sm:text-center">
                <Typography
                    as="a"
                    href="#"
                    className="mr-4 cursor-pointer py-1.5 lg:ml-2 flex justify-center items-center golden-hopes-big-title"
                >
                    Le Gîte de Marie
                </Typography>
                <p className="leading-relaxed mt-2 text-[15px]">
                    Gîte de Marie, Préfailles, Loire-Atlantique, Pays de la Loire, France
                </p>
            </div>
            <div className="max-w-lg sm:mx-auto sm:text-center">
                <Button variant="gradient" size="sm" className="m-2" onClick={scrollToTop}>
                <span className="flex items-center josefin-sans-bold">
                    <ArrowUpIcon className="ml-0 mr-3 h-4 w-4"/>
                    Revenir en haut
                </span>
                </Button>
            </div>
            <div className="mt-8 items-center justify-between sm:flex mb-12">
                <div className="mt-4 sm:mt-0 dm-sans-300">
                    &copy; 2024 Gîte de Marie - Tous droits réservés
                </div>
                <div className="mt-4 sm:mt-0 dm-sans-300">
                    Design by <a href="https://www.dlnntheo.fr" className="text-indigo-600">Delannoy Théo</a>
                </div>
            </div>
            <style jsx>{`
                .svg-icon path,
                .svg-icon polygon,
                .svg-icon rect {
                    fill: currentColor;
                }
            `}</style>
        </footer>
    )
}

export default Footer;
