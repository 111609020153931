export function GuestZoneNotAvailable(){
    return (
        <section className="py-14">
            <div className="max-w-screen-xl mx-auto px-4 md:text-center md:px-8">
                <div className="max-w-xl md:mx-auto">
                    <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                        Cette section n'est pas disponible pour le moment
                    </h3>
                    <p className="mt-3 text-gray-600">
                        Afin d'avoir des inforamtions supplémentaires pour votre voyage, je vous invite à nous contacter directement.
                    </p>
                </div>
                <div className="flex gap-3 items-center mt-4 md:justify-center">
                    <a href="/contact" className="inline-block py-2 px-4 text-white font-medium bg-gray-800 duration-150 hover:bg-gray-700 active:bg-gray-900 rounded-lg shadow-md hover:shadow-none">
                        Nous contacter
                    </a>
                </div>
            </div>
        </section>
    )

}