import React, {useEffect, useState} from "react"

import {StarIcon} from "@heroicons/react/16/solid";
import AliceCarousel from "react-alice-carousel";

const testimonials = [
    {
        name: "Carine",
        title: "Nozay, France",
        quote: "Accueil parfait !\n Gîte parfait, propre, lumineux, spacieux très bien équipé\n" +
            "Super veranda, cuisine et terrasse...\n" +
            "Tout simplement génial👍👍\n" +
            "Nous espérons y retourner l'année prochaine pour passer un week-end avec nos amis aussi merveilleux que celui-ci\n" +
            "Mille Merci à Hervé et Marie pour leur magnifique gîte !!!"
    },
    {
        name: "Elodie",
        title: "Nancy, France",
        quote: "Gite très agréable ou on se sent chez soi, très bien équipé, literie très confortable. Hôtes très sympathiques et arrangeants"
    },
    {
        name: "Marie Christine",
        title: "Paris, France",
        quote: "Marie Laure nous a accueilli avec le sourire ce qui est des plus agréables. Le gîte est conforme à la description, calme et paisible. La plage est à peine à 10 minutes à pied du gîte d'où l'on peut faire aussi de belle balades côtières.\n" +
            "Nous avons passé un charmant We et reviendrons sûrement."
    },
    {
        name: "Élodie",
        title: "Paris, France",
        quote: "Superbe maison a 800m de la plage avec tout le confort nécessaire pour un petit séjour en famille. Accueil très sympathique de Marie-Laure."
    },
    {
        name: "Florian",
        title: "France",
        quote: "Hôtes très agréables, présents à l'arrivée malgré l'heure tardive. Ont pris le temps de faire le tour du gîte et de nous fournir toutes les explications.\n" +
            "Nous recommandons vivement ce gîte et ces hôtes ainsi que le lieu ( au calme, près de la mer et du centre ville.\n" +
            "A bientôt"
    },
]



function Review(){
    const [currentTestimonial, setCurrentTestimonial] = useState(0)

    useEffect(() => {
        const nextTestimonial = () => {
            setCurrentTestimonial((prevIndex) =>
                prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
            );
        };

        const intervalId = setInterval(nextTestimonial, 20000);

        return () => clearInterval(intervalId);
    }, []);

    const handleSetActiveIndex = (index) => {
        setCurrentTestimonial(index);
    };

    return (
        <section className="py-14">
            <div className="max-w-screen-xl mx-auto px-4 md:px-8">
                <div className="max-w-3xl mx-auto text-center">
                    <div className="flex items-center justify-center" href="https://www.airbnb.fr/rooms/53333677?guests=1&adults=1&s=67&unique_share_id=985fbddd-cd2e-49af-8649-ef6987858d83">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-16" viewBox="0 0 48 48">
                            <path fill="#ff5252"
                                  d="M42.459,32.519c-1.037-3.336-9.539-19.596-12.12-24.5l-0.026-0.048C29.153,5.559,26.676,4,24,4 s-5.153,1.559-6.291,3.929L17.661,8.02C15.08,12.923,6.578,29.183,5.542,32.518C5.261,33.421,5,34.407,5,35.5 c0,4.687,3.813,8.5,8.5,8.5c4.654,0,7.612-1.949,10.5-5.184C26.888,42.051,29.846,44,34.5,44c4.687,0,8.5-3.813,8.5-8.5 C43,34.407,42.739,33.421,42.459,32.519z M23.999,34.662C22.33,32.515,20,28.881,20,26c0-2.206,1.794-4,4-4s4,1.794,4,4 C28,28.872,25.668,32.511,23.999,34.662z M34.5,41c-3.287,0-5.521-1.107-8.325-4.258C27.878,34.596,31,30.104,31,26 c0-3.86-3.141-7-7-7s-7,3.14-7,7c0,4.104,3.122,8.596,4.825,10.742C19.021,39.893,16.787,41,13.5,41C10.468,41,8,38.533,8,35.5 c0-0.653,0.162-1.308,0.406-2.09C9.17,30.95,15.3,18.948,20.316,9.417l0.076-0.146C21.055,7.891,22.471,7,24,7 s2.945,0.891,3.615,2.285l0.068,0.132C32.7,18.948,38.83,30.95,39.595,33.411C39.838,34.192,40,34.847,40,35.5 C40,38.533,37.532,41,34.5,41z"></path>
                        </svg>
                    </div>
                    <h3 className="text-2xl sm:text-4xl dm-sans-700 mb-8">Nos voyageurs parlent de nous</h3>

                    <AliceCarousel
                        autoPlay
                        autoPlayInterval={10000}
                        infinite
                        mouseTracking
                        disableButtonsControls
                        disableDotsControls
                    >
                        {testimonials.map((item, idx) => (
                            <div key={idx} className="flex flex-col justify-center items-center h-full">
                                <p className="text-gray-800 text-xl font-semibold sm:text-2xl">“{item.quote}“</p>
                                <div className="mt-6">
                                    <div className="flex items-center justify-center mb-2">
                                        <StarIcon className="ml-0 mr-0 h-6 w-6"/>
                                        <StarIcon className="ml-0 mr-0 h-6 w-6"/>
                                        <StarIcon className="ml-0 mr-0 h-6 w-6"/>
                                        <StarIcon className="ml-0 mr-0 h-6 w-6"/>
                                        <StarIcon className="ml-0 mr-0 h-6 w-6"/>
                                    </div>
                                    <div className="mt-3">
                                        <span className="block text-gray-800 dm-sans-500">{item.name}</span>
                                        <span
                                            className="block text-gray-600 text-sm mt-0.5 dm-sans-300">{item.title}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </AliceCarousel>
                </div>
            </div>
        </section>
    )
}

export default Review;