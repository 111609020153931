import Home from './componants/home/Home';
import {Carousel_home} from "./componants/home/Carousel";
import Numbers from './componants/home/Numbers';
import NewExperience from "./componants/home/NewExperience";

function HomePage() {
  return (
    <div>
        <Home />
        <Numbers />
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <Carousel_home />
        <NewExperience/>
    </div>
  );
}

export default HomePage;

