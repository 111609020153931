import ModalRentalSelect from "../global/ModalRentalSelect";
import React from "react";
import InformNewExperiment from "./ModalNewExp";

function NewExperience() {

    const [openModal, setOpenModal] = React.useState(false);
    const handleModalOpen = () => {
        setOpenModal(true);
    };
    const handleModalClose = () => {
        setOpenModal(false);
    }

    return (
        <div>
        <section className="relative overflow-hidden py-28 px-4 bg-gray-900 md:px-8">
            <div className="w-full h-full rounded-full bg-gradient-to-r from-[#58AEF1] to-pink-500 absolute -top-12 -right-14 blur-2xl opacity-10"></div>
            <div className="max-w-xl mx-auto text-center relative">
                <div className="py-4">
                    <p className="golden-hopes text-white text-7xl">
                        Bientôt disponible
                    </p>
                    <h3 className="text-xl text-gray-200 font-semibold md:text-4xl">
                        Découvrez une nouvelle expérience au courant 2024
                    </h3>
                    <p className="text-gray-300 leading-relaxed mt-3">
                        En 2024 une nouvelle expérience vous attend. Venez découvrir notre séjour créatif et artistique.
                        Un séjour qui vous permettra de vous ressourcer et de vous reconnecter à votre créativité.
                        Profitez de l'atelier créatif pour vous exprimer et vous détendre.
                    </p>
                </div>
                <div className="mt-5 items-center justify-center gap-3 sm:flex" >
                    <a
                        onClick={handleModalOpen} href="#"
                        className="block w-full mt-2 py-2.5 px-8 text-gray-700 bg-white rounded-md duration-150 hover:bg-gray-100 sm:w-auto"
                    >
                        M'informer de l'ouverture
                    </a>
                </div>
            </div>
        </section>
        {openModal && (
            <div>
                <div className="overlay"></div>
                <InformNewExperiment handleModalClose={handleModalClose} />
            </div>
        )}
        </div>
    )
}


export default NewExperience