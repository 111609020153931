import React, { useState, useEffect } from 'react';
import {ArrowUpIcon, ArrowUpRightIcon} from "@heroicons/react/24/solid";
import ModalRentalSelect from "./ModalRentalSelect";
import {Button} from "@material-tailwind/react";
import { useMediaQuery } from '@react-hook/media-query';
import NavbarWithMegaMenu from "./Navbar";

const GoToVendor = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const isMobile = useMediaQuery('(max-width: 768px)');
    const isVisibleToTop = true;

    const handleModalOpen = () => {
        setOpenModal(true);
    };

    const handleModalClose = () => {
        setOpenModal(false);
    }
    const toggleVisibility = () => {
        if (window.pageYOffset > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Ajouter un écouteur d'événement de défilement lors du chargement du composant
    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);

        // Nettoyer l'écouteur d'événement lorsque le composant est démonté
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    // Fonction pour faire défiler vers le haut de la page
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div>
            <div
                className={`fixed bottom-4 right-4 z-50 ${isVisible ? 'opacity-100 transition-opacity duration-1500' : 'opacity-0 transition-opacity duration-1500'}`}>
                {isVisible && (
                    <Button variant="gradient" size="sm" onClick={handleModalOpen}>
                        <span className="flex items-center josefin-sans-bold">
                            <ArrowUpRightIcon className="ml-0 mr-3 h-4 w-4"/>
                            Réservez vos vacances
                        </span>
                    </Button>
                )}
            </div>
        <div className="fixed top-2 left-1/2 transform -translate-x-1/2 z-30 w-full max-w-screen-xl ${isVisible ? 'opacity-100 transition-opacity duration-1500' : 'opacity-0 transition-opacity duration-1500'}`}">
                {isVisible && (
                    <NavbarWithMegaMenu/>
                )}
            </div>
            <div
                className={`fixed bottom-4 left-4 z-50 ${isVisible ? 'opacity-100 transition-opacity duration-1500' : 'opacity-0 transition-opacity duration-1500'}`}>
                {isVisible && (
                    <Button variant="gradient" size="sm" onClick={scrollToTop}>
                    <span className="flex items-center josefin-sans-bold">
                        <ArrowUpIcon className="h-4 w-4"/>
                        {isMobile ? null : "Revenir en haut"}
                    </span>
                    </Button>
                )}
            </div>
            {openModal && (
                <div>
                    <div className="overlay"></div>
                    <ModalRentalSelect handleModalClose={handleModalClose}/>
                </div>
            )}
        </div>
    );
};

export default GoToVendor;
