import React from "react";
import ModalRentalSelect from "./ModalRentalSelect";
import {
    Navbar,
    Collapse,
    Typography,
    Button,
    IconButton,
    List,
    ListItem,
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
} from "@material-tailwind/react";
import {
    ChevronDownIcon,
    Bars3Icon,
    XMarkIcon,
    HomeIcon,
} from "@heroicons/react/24/outline";
import {
    SquaresPlusIcon,
    UserGroupIcon,
    BookOpenIcon,
    PhotoIcon,
    MapPinIcon,
    ShoppingCartIcon,
    ArrowUpRightIcon,
} from "@heroicons/react/24/solid";

import logo_gdm from "../../img/logo/logo_couleur.webp";
import logo_booking from "../../img/logo/logo_booking.svg";
import logo_airbnb from "../../img/logo/logo_airbnb.png";

const navListMenuItems = [
    {
        title: "L'histoire du Gîte",
        description: "Retrouvez l'histoire du Gîte de Marie",
        icon: BookOpenIcon,
        href: "/histoire",
    },
    {
        title: "Galerie Photo",
        description: "Découvrez les photos du Gîte",
        icon: PhotoIcon,
        href: "/galerie",
    },
    {
        title: "Espace invités",
        description: "Retrouvez les informations pour les voyageurs",
        icon: UserGroupIcon,
        href: "/espace-invites",
    },
    /*
    {
        title: "Les environs",
        description: "Les lieux incontournables de la côte",
        icon: MapPinIcon,
        href: "/environs",
    },
    {
        title: "Les commerces",
        description: "Les commerces de proximité",
        icon: ShoppingCartIcon,
        href: "/commerces",
    },
    */
];

function NavListMenu() {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
    const renderItems = navListMenuItems.map(
        ({ icon, title, description, href }, key) => (
            <a href={href} key={key}>
                <MenuItem className="flex items-center gap-3 rounded-lg">
                    <div className="flex items-center justify-center rounded-lg !bg-blue-gray-50 p-2 ">
                        {" "}
                        {React.createElement(icon, {
                            strokeWidth: 2,
                            className: "h-6 text-gray-900 w-6",
                        })}
                    </div>
                    <div>
                        <Typography
                            variant="h6"
                            color="blue-gray"
                            className="flex items-center text-sm josefin-sans-bold"
                        >
                            {title}
                        </Typography>
                        <Typography
                            variant="paragraph"
                            className="text-xs !josefin-sans-regular text-blue-gray-500"
                        >
                            {description}
                        </Typography>
                    </div>
                </MenuItem>
            </a>
        ),
    );

    return (
        <React.Fragment>
            <Menu
                open={isMenuOpen}
                handler={setIsMenuOpen}
                offset={{ mainAxis: 20 }}
                placement="bottom"
                allowHover={true}
            >
                <MenuHandler>
                    <Typography as="div" variant="H6" className="josefin-sans-bold">
                        <ListItem
                            className="flex items-center gap-2 py-2 pr-4 josefin-sans-bold text-gray-900"
                            selected={isMenuOpen || isMobileMenuOpen}
                            onClick={() => setIsMobileMenuOpen((cur) => !cur)}
                        >
                            Le Gîte
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`hidden h-3 w-3 transition-transform lg:block ${
                                    isMenuOpen ? "rotate-180" : ""
                                }`}
                            />
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`block h-3 w-3 transition-transform lg:hidden ${
                                    isMobileMenuOpen ? "rotate-180" : ""
                                }`}
                            />
                        </ListItem>
                    </Typography>
                </MenuHandler>
                <MenuList className="hidden max-w-screen-xl rounded-xl lg:block">
                    <ul className="grid grid-cols-3 gap-y-2 outline-none outline-0">
                        {renderItems}
                    </ul>
                </MenuList>
            </Menu>
            <div className="block lg:hidden">
                <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
            </div>
        </React.Fragment>
    );
}

function NavList() {
    return (
        <List className="mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">
            <Typography
                as="a"
                href="/"
                variant="h6"
                color="blue-gray"
                className="josefin-sans-bold"
            >
                <ListItem className="flex items-center gap-2 py-2 pr-4">
                    <HomeIcon className="h-4 w-4" /> {/* Icône UserGroupIcon */}
                    Accueil
                </ListItem>
            </Typography>
            <NavListMenu />
            <Typography
                as="a"
                href="/contact"
                variant="H6"
                color="blue-gray"
                className="josefin-sans-bold"
            >
                <ListItem className="flex items-center gap-2 py-2 pr-4">
                    Contactez-nous
                </ListItem>
            </Typography>
        </List>
    );
}


export function NavbarWithMegaMenu() {
    const [openNav, setOpenNav] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);


    const handleModalOpen = () => {
        setOpenModal(true);
    };

    const handleModalClose = () => {
        setOpenModal(false);
    }

    React.useEffect(() => {
        window.addEventListener(
            "resize",
            () => window.innerWidth >= 960 && setOpenNav(false),
        );
    }, []);

    return (
        <div>
            <Navbar className="mx-auto max-w-screen-xl px-4 py-2">
            <div className="flex items-center justify-between text-blue-gray-900">
                <Typography
                    as="a"
                    href="/"
                    className=" py-1 lg:ml-2 flex items-center golden-hopes-big-title Color-Blue-Klein"
                >
                    Le Gîte de Marie
                </Typography>
                <div className="hidden lg:block">
                    <NavList/>
                </div>
                <div className="hidden gap-2 lg:flex josefin-sans-bold">
                    <Button variant="gradient" size="sm" onClick={handleModalOpen}>
                        <span className="flex items-center josefin-sans-bold">
                            <ArrowUpRightIcon className="ml-0 mr-3 h-4 w-4" />
                            Réservez vos vacances
                        </span>
                    </Button>
                </div>
                <IconButton
                    variant="text"
                    color="blue-gray"
                    className="lg:hidden"
                    onClick={() => setOpenNav(!openNav)}
                >
                    {openNav ? (
                        <XMarkIcon className="h-6 w-6" strokeWidth={2}/>
                    ) : (
                        <Bars3Icon className="h-6 w-6" strokeWidth={2}/>
                    )}
                </IconButton>
            </div>
            <Collapse open={openNav}>
                <NavList/>
                <div className="flex w-full flex-nowrap items-center gap-2 lg:hidden">
                    <Button variant="gradient" size="sm" onClick={handleModalOpen}>
                        <span className="flex items-center josefin-sans-bold">
                            <ArrowUpRightIcon className="ml-0 mr-3 h-4 w-4" />
                            Réservez vos vacances
                        </span>
                    </Button>
                </div>
            </Collapse>
        </Navbar>
        {openModal && (
            <div>
            <div className="overlay"></div>
                <ModalRentalSelect handleModalClose={handleModalClose} />
            </div>
        )}
        </div>

    );
}

export default NavbarWithMegaMenu;
