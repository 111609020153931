import { useState, useEffect } from 'react';

export function MailSended() {
    const [showNotification, setShowNotification] = useState(false);

    useEffect(() => {
        // Afficher la notification pendant 3 secondes
        setShowNotification(true);
        const timer = setTimeout(() => setShowNotification(false), 3000);

        // Nettoyer le timer lorsque le composant est démonté
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {showNotification && (
                <div className="fixed top-0 right-0 z-50 m-4">
                    <div className="flex justify-between p-4 rounded-md bg-green-50 border border-green-300">
                        <div className="flex items-start gap-3 w-full">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                </svg>
                            </div>
                            <div className="flex-1 self-center">
                                <span className="dm-sans-500">
                                    🚀 5 sur 5, le mail a été envoyé avec succès
                                </span>
                            </div>
                            <button onClick={() => setShowNotification(false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                     className="w-5 h-5 text-green-600">
                                    <path
                                        d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export function ErrorDuringSending() {
    const [showNotification, setShowNotification] = useState(false);

    useEffect(() => {
        // Afficher la notification pendant 3 secondes
        setShowNotification(true);
        const timer = setTimeout(() => setShowNotification(false), 3000);

        // Nettoyer le timer lorsque le composant est démonté
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {showNotification && (
                <div className="fixed top-0 right-0 z-50 m-4">
                    <div className="flex justify-between p-4 rounded-md bg-red-50 border border-red-300">
                        <div className="flex items-start gap-3 w-full">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                </svg>
                            </div>
                            <div className="flex-1 self-center">
                                <span className="dm-sans-500">
                                    🚨 Oups, une erreur est survenue lors de l'envoi du mail <br/> Veuillez réessayer plus tard
                                </span>
                            </div>
                            <button onClick={() => setShowNotification(false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                     className="w-5 h-5 text-red-600">
                                    <path
                                        d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
